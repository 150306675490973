<template>
    <v-main>
        <v-container>
            <v-row class="mb-3">
                <v-col md="6" sm="12" cols="12" lg="6">
                    <MainFilter @onInputChange="filterInputChanged" @onClick="showFilters" />
                </v-col>

                <v-col md="6" sm="12" cols="12" lg="6" align="right">
                    <ExportButton @onClick="onExport" />
                </v-col>
            </v-row>

            <v-row v-show="filter" class="mb-4">
                <Filters :loadFilters="false" @onApply="searchListener" />
            </v-row>
            <v-data-table
                :items="manufacturersList"
                hide-default-footer
                class="elevation-0 pb-4 flat-container"
                :headers="headers"
                @click:row="onClickRow"
            >
                <template v-slot:[`item.status`]="{ item }">
                    <v-chip small :class="`status-chip ${getStatus(item.status).color}`">
                        {{ getStatus(item.status).label }}
                    </v-chip>
                </template>
                <template v-slot:[`item.relationshipDate`]="{ item }">
                    {{ formatDate(item.relationshipDate) }}
                </template>

                <template slot="no-data">
                    <div>Não há dados</div>
                </template>

                <template v-slot:footer>
                    <v-pagination
                        color="secondary"
                        v-model="currentPage"
                        class="mt-3"
                        :length="manufacturersListTotalPages"
                        :total-visible="7"
                    ></v-pagination>
                </template>
            </v-data-table>
            <v-row
                align="center"
                justify="center"
                class="mt-6"
                v-if="manufacturersListRequestStatus === RequestStatusEnum.START"
            >
                <v-progress-circular
                    :size="70"
                    :width="6"
                    color="secondary"
                    indeterminate
                ></v-progress-circular>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { RequestStatusEnum, RegistersStatus } from '@/helpers/consts';
import { consultHeader as headers } from '../_headers';
import { formatarDataBarra } from '@/helpers/data';
import Filters from './_Filters';
import { notification } from '@/helpers/notification';
import fileSaver from '@/helpers/fileSaver';

import MainFilter from '@/components/base/mainfilter/MainFilter';
import ExportButton from '@/components/base/ExportButton';

export default {
    components: {
        Filters,
        MainFilter,
        ExportButton,
    },
    data() {
        return {
            filter: false,
            filters: {},
            page: 0,
            headers,
            RequestStatusEnum,
            formatarDataBarra,
            RegistersStatus,
        };
    },
    methods: {
        ...mapActions('cadastros', {
            fetchManufacturers: 'fetchManufacturers',
            exportManufacturers: 'exportManufacturers',
        }),
        ...mapMutations({
            setManufacturer: 'cadastros/setManufacturerDetail',
        }),
        onClickRow(data) {
            this.setManufacturer(data);
        },
        getStatus(key) {
            return RegistersStatus[parseInt(key, 10)];
        },
        formatDate(date) {
            return date ? formatarDataBarra(date) : '';
        },
        async doSearch() {
            let filters = {
                ...this.filters,
                page: this.page,
                limit: 10,
            };
            const payload = {
                idProduct: this.selectedProduct.id,
                filters,
            };
            await this.fetchManufacturers(payload);
        },
        onExport() {
            const payload = {
                idProduct: this.selectedProduct.id,
                filters: this.filters,
            };
            this.exportManufacturers(payload);
        },
        filterInputChanged(value) {
            this.filters.nomeOuDocumento = value;
        },
        searchListener(data) {
            this.filters = { ...this.filters, ...data };
            this.doSearch();
        },
        showFilters() {
            this.filter = !this.filter;
        },
    },
    watch: {
        page() {
            this.doSearch();
        },
        manufacturer(value) {
            if (value) {
                this.$router.push({ path: `/admin/cadastros/produtores/editar/${value.id}` });
            }
        },
        exportManufacturersRequestStatus(newValue) {
            if (newValue === RequestStatusEnum.SUCCESS) {
                const fileName = 'gestao_titulos.xlsx';
                return fileSaver(
                    this.exportManufacturersData.file,
                    this.exportManufacturersData.file.type,
                    fileName
                );
            }
            if (newValue.type === RequestStatusEnum.ERROR) {
                notification(
                    this.$dialog.notify.error,
                    'Ocorreu um erro ao fazer a exportação: ' + newValue.message
                );
                return RequestStatusEnum.ERROR;
            }
        },
    },

    computed: {
        ...mapGetters('cadastros', {
            manufacturersList: 'manufacturersList',
            manufacturersListRequestStatus: 'manufacturersListRequestStatus',
            manufacturersListTotalPages: 'manufacturersListTotalPages',
            manufacturer: 'manufacturerDetail',
            selectedProduct: 'selectedProduct',
            exportManufacturersRequestStatus: 'exportManufacturersRequestStatus',
            exportManufacturersData: 'exportManufacturers',
        }),
        currentPage: {
            get() {
                return this.page + 1;
            },
            set(page) {
                this.page = page - 1;
                return page;
            },
        },
    },
    async created() {
        await this.doSearch();
    },
};
</script>
