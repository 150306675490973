<template>
    <v-main>
        <v-form ref="form" class="ml-3 mr-3">
            <v-row>
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-select
                        color="secondary"
                        v-model="filters.gerente"
                        :items="managers"
                        item-text="name"
                        item-value="id"
                        label="Gerente"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3">
                    <v-select
                        color="secondary"
                        v-model="filters.estado"
                        :items="states"
                        label="UF"
                    ></v-select>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                    <v-menu
                        ref="menuEmissao"
                        v-model="menuEmissao"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="relacionamentoRange"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                class="v-input-default"
                                color="secondary"
                                v-model="relacionamentoRange"
                                label="Data (Inicio/fim) relacionamento"
                                prepend-icon="event"
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-if="menuEmissao"
                            v-model="relacionamento_inicio"
                            no-title
                            range
                            scrollable
                            locale="pt-br"
                        >
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" depressed @click="menuEmissao = false"
                                >Cancelar</v-btn
                            >
                            <v-btn
                                color="secondary"
                                depressed
                                @click="
                                    $refs.menuEmissao.save(
                                        formatDateRangeSetKeys(
                                            relacionamento_inicio,
                                            'relacionamento'
                                        )
                                    )
                                "
                                >Salvar</v-btn
                            >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="mt-3">
                    <v-btn depressed @click="apply" color="secondary" class="v-btn-padded">
                        <span class="text-none">Aplicar</span>
                    </v-btn>

                    <v-btn
                        outlined
                        @click="onFilterClear"
                        depressed
                        color="secondary"
                        class="ml-4 v-btn-padded"
                    >
                        <span class="text-none">Limpar</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-main>
</template>

<script>
import { mapGetters } from 'vuex';
import { formatDate } from '@/helpers/data';

export default {
    props: {
        loadFilters: {
            type: Boolean,
            default: true,
        },
        enableStatusOnly: {
            type: Array,
            default: () => [],
        },
    },
    data: () => ({
        filters: {},
        menuEmissao: false,
        relacionamento_inicio: null,
        relacionamentoRange: null,
        relacionamento_final: null,
    }),

    computed: {
        ...mapGetters('cadastros', {
            selectedProduct: 'selectedProduct',
            states: 'statesEnumAsArray',
            managers: 'userList',
        }),
    },
    methods: {
        async apply() {
            this.$emit('onApply', { ...this.filters });
        },
        onFilterClear() {
            this.filters = {};
            this.$emit('onApply', { ...this.filters });
            this.relacionamento_inicio = null;
            this.relacionamentoRange = null;
            this.relacionamento_final = null;
        },

        formatDateRangeSetKeys(range, field) {
            this.filters[field + '_inicio'] = range[0];
            if (range.length > 1) {
                this.filters[field + '_final'] = range[1];
                this[field + 'Range'] =
                    formatDate(this.filters[field + '_inicio']) +
                    ' a ' +
                    formatDate(this.filters[field + '_final']);
            } else {
                this[field + 'Range'] = formatDate(this.filters[field]);
                delete this.filters[field + '_final'];
            }
            return this[field + 'Range'];
        },
    },
};
</script>
