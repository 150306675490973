export const pendingHeaders = [
    {
        text: 'Cliente',
        sortable: false,
        value: 'name',
    },
    {
        text: 'CNPJ/CPF',
        sortable: false,
        value: 'documento',
        align: 'center',
        width: 160,
    },
    {
        text: 'Status',
        sortable: false,
        value: 'status',
        align: 'center',
        width: 160,
    },
    {
        text: 'Relacionamento',
        sortable: false,
        value: 'relationship',
        align: 'center',
        width: 160,
    },
    {
        text: 'Produto',
        sortable: false,
        value: 'product',
    },
    {
        text: 'Documentos',
        sortable: false,
        value: 'documentos',
        align: 'center',
        width: 96,
    },
];
export const consultHeader = [
    {
        text: 'Cliente',
        sortable: false,
        value: 'customer',
    },
    {
        text: 'CNPJ/CPF',
        sortable: false,
        value: 'document',
        align: 'center',
        width: 160,
    },
    {
        text: 'Status',
        sortable: false,
        value: 'status',
        align: 'center',
        width: 60,
    },
    {
        text: 'Relacionamento',
        sortable: false,
        value: 'relationshipDate',
        align: 'center',
        width: 160,
    },
    {
        text: 'UF',
        sortable: false,
        value: 'state',
        width: 48,
    },
    {
        text: 'Gerente',
        sortable: false,
        value: 'manager',
        align: 'center',
    },
];
