var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('v-form',{ref:"form",staticClass:"ml-3 mr-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('v-select',{attrs:{"color":"secondary","items":_vm.managers,"item-text":"name","item-value":"id","label":"Gerente"},model:{value:(_vm.filters.gerente),callback:function ($$v) {_vm.$set(_vm.filters, "gerente", $$v)},expression:"filters.gerente"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('v-select',{attrs:{"color":"secondary","items":_vm.states,"label":"UF"},model:{value:(_vm.filters.estado),callback:function ($$v) {_vm.$set(_vm.filters, "estado", $$v)},expression:"filters.estado"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-menu',{ref:"menuEmissao",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.relacionamentoRange,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.relacionamentoRange=$event},"update:return-value":function($event){_vm.relacionamentoRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"v-input-default",attrs:{"color":"secondary","label":"Data (Inicio/fim) relacionamento","prepend-icon":"event","readonly":""},model:{value:(_vm.relacionamentoRange),callback:function ($$v) {_vm.relacionamentoRange=$$v},expression:"relacionamentoRange"}},on))]}}]),model:{value:(_vm.menuEmissao),callback:function ($$v) {_vm.menuEmissao=$$v},expression:"menuEmissao"}},[(_vm.menuEmissao)?_c('v-date-picker',{attrs:{"no-title":"","range":"","scrollable":"","locale":"pt-br"},model:{value:(_vm.relacionamento_inicio),callback:function ($$v) {_vm.relacionamento_inicio=$$v},expression:"relacionamento_inicio"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.menuEmissao = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"secondary","depressed":""},on:{"click":function($event){_vm.$refs.menuEmissao.save(
                                    _vm.formatDateRangeSetKeys(
                                        _vm.relacionamento_inicio,
                                        'relacionamento'
                                    )
                                )}}},[_vm._v("Salvar")])],1):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-3"},[_c('v-btn',{staticClass:"v-btn-padded",attrs:{"depressed":"","color":"secondary"},on:{"click":_vm.apply}},[_c('span',{staticClass:"text-none"},[_vm._v("Aplicar")])]),_c('v-btn',{staticClass:"ml-4 v-btn-padded",attrs:{"outlined":"","depressed":"","color":"secondary"},on:{"click":_vm.onFilterClear}},[_c('span',{staticClass:"text-none"},[_vm._v("Limpar")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }